import { render, staticRenderFns } from "./AccountingBookSelect.vue?vue&type=template&id=642b983e"
import script from "./AccountingBookSelect.vue?vue&type=script&lang=ts"
export * from "./AccountingBookSelect.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports